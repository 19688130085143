
// Fonts
@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,600);

// Variables
@import "variables";



// Some overrides copied from DiGS  ------------------------
$body-bg: #222222;
$font-family-sans-serif: "Gotham", Arial, Helvetica, sans-serif;
$font-size-base: 15.4px;
$line-height-base: 1.6;
$text-color: #636b6f;
//----------------------------------------------------------


// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

// GSNSW
@import "gsnsw";

